$(document).ready(function () {
    $('#super_admin').change(function() {
        if($(this).is(":checked"))
        {
            $('#bg_admin').attr('checked', false);
            $('#ru_admin').attr('checked', false);
            $('#bg_admin').attr("disabled", true);
            $('#ru_admin').attr("disabled", true);
        }
        else
        {
            $('#bg_admin').removeAttr("disabled");
            $('#ru_admin').removeAttr("disabled");
        }
    });

    $('.modal').on('shown.bs.modal', function () {
       $(this).data('bs.modal', null);
    })

    $('.historyOrder').on('click', function (e) {
        e.preventDefault();
        var url = this.href;
        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                $('.sidebar-panel .history').show().html(data);
            }
        });
    })
    
    $('.editOrder').on('click', function (e) {
        e.preventDefault();
        var url = this.href;
        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                $('.sidebar-panel .edit').show().html(data);
                $('.sidebar-panel .history').hide();
            }
        });
    })
    
    $('.orderHistory').on('click', function (e) {
        e.preventDefault();  
        $('.sidebar-panel .edit').hide();
        $('.sidebar-panel .history').hide();
    })

    $('.check').on('click', function () {
        if ($(".check").is(':checked')) {
            $("input[type=checkbox]").each(function () {
                $(this).prop("checked", true);
            });

        } else {
            $("input[type=checkbox]").each(function () {
                $(this).prop("checked", false);
            });
        }
    })

    $('.send').click(function() {
        $('.mb-form').submit();
        $(this).attr('disabled','disabled');
    }); 

    $( "#start" ).datepicker({ format: 'yyyy-mm-dd', defaultDate:'now'})
        .on('changeDate', function(){
            $(this).val($(this).val() + ' 00:00:00')
        });
    
    $( "#end" ).datepicker({ format: 'yyyy-mm-dd', defaultDate:'now'})
        .on('changeDate', function(){
            $(this).val($(this).val() + ' 00:00:00')
        });

});